.fff-carousel-wrap {
    .rotator .rotator-title {
        height: 0;
        padding: 0;
    }
    .rotator-panels-wrapper {
        margin-left: 42px;
        overflow: hidden;
        width: 506px
    }
    .rotator-pages .ico {
        background-position-y: -198px;
        margin-bottom: 63px;
        margin-top: 63px;
    }
    .shallow-ac {
        overflow: visible;
        &.mini .rotator {
            height: auto;
            margin-left: 0;
            width: 590px;
            .rotator-panels li {
                height: 255px;
                margin-right: 8px;
                padding: 0;
                width: 162px;

                a {
                    background-color: #ffffff;
                }

                span.dotted {
                    border-bottom: 1px dotted gray;
                    border-left: 0;
                    border-right: 0;
                    border-top: 0;
                    margin-bottom: 6px;
                    width: 100%;
                    position: relative;
                }
            }
            .rotator-pages {
                div, div .bg, div a {
                    height: 200px;
                }
            }
        }
        .rotator {
            .rotator-panels img {
                display: inline;
                height: 140px;
                margin: 10px 0;
                max-width: 160px;
                width: auto;
            }
            .rotator-pages {
                .rotator-prev {
                    left:0;
                }
                .rotator-next {
                    right: 4px;
                }
            }
        }
        .rotator-pages .disabled .bg {
            background-color: white;
        }
    }
    .rotator-next.disabled .ico,
    .rotator-prev.disabled .ico{
        opacity: 0.3;
    }
}

img.loader {
    position: absolute;
    top: 140px;
    left: 300px;
}

img.product-loader {
    position: absolute;
    top: 75px;
    left: 70px;
}


.fff-carousel-wrap {
    margin-bottom: 0;
    padding-bottom: 15px;
    position: relative;
    text-align: center;
    .fff-carousel {
        display: inline-block;
        overflow: hidden;
        position: relative;
        touch-action: pan-y;
        width: 505px;
        ul {
            margin: 0;
            padding: 0;
            position: relative;
            width: 20000em;
        }
    }
    .fff-carousel-item {
        float: left;
        font-size: 12px;
        height: 255px;
        list-style: none;
        margin-right: 8px;
        padding: 0;
        position: relative;
        width: 162px;
        img {
            height: 140px;
            margin: 10px 0;
            max-width: 160px;
        }
        &:hover {
            opacity: 0.85;
        }
        .fff-related-product-container {
            border: 1px solid #E3E3E3;
            border-bottom: 4px solid #E7C1DA;
        }
    }
    .fff-more-products-title {
        background-color: #fff;
        color: black;
        float: left;
        font-size: 18px;
        font-weight: bold;
        margin: 0 65px;
        padding: 20px 0;
    }
}

.fff-mini-price-badge {
    position: absolute;
    top: 5px;
    right: 2px;
    width: 57px;
    height: 57px;
    border-radius: 20px;
    background: url('https://i.dailymail.co.uk/i/furniture/fff/price_small.png') no-repeat;
}

.fff-mini-price-badge-content {
    color: #C562A5;
    padding-top: 22px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.fff-pink-line {
    background-color: #C562A5;
    border: 0;
    display: block;
    float: left;
    height: 1px;
    margin: 0;
    width: 100%;
}

.fff-ribbon {
    position: absolute;
    top: 0;
    left: 0;
    width: 78px;
    height: 78px;
    background-size: 100% 100%;
}

//Mobile override
.fff-m-expandable, .fff-m-popup {
    li img {
        position: static;
    }
    img.loader {
        display: block;
        margin: 150px auto;
        position: static;
        width: 32px;
    }
    .fff-mini-price-badge {
        position: absolute;
        div {
            height: 54px;
        }
    }
    .fff-carousel-wrap {
        height: 274px;
        margin: 0;
        padding: 0;
        .fff-ribbon {
            height: 78px;
            width: 78px;
        }
    }
    .fff-carousel-with-controls {
        height: 290px;
    }
    .fff-carousel-wrap .fff-carousel {
        border-top: 0px;
        display: block;
        height: 274px;
        margin: 0;
        padding: 0;
        width: 100%;
        ul {
            margin: 0;
        }
        .fff-carousel-item {
            border: 0;
            height: 275px;
            padding: 0 15px;
        }
        .fff-ribbon {
            left: 0;
            position: absolute;
        }
    }
    .fff-carousel-control {
        background-color: transparent;
        display: inline-block;
        height: 55px;
        position: absolute;
        top: 80px;
        width: 25px;
        &:after {
            border-color: #c562a5;
        }
    }
    .fff-product-blurb {
        margin-bottom: 15px;
    }
    .fff-related-product-item {
        height: auto;
        max-width: 140px;
        > a {
            display: inline;
            height: auto;
            width: auto;
        }
    }
}