.fff-m-inline.fff-m-popup {
    border: 2px solid #a9a9a9;
    box-sizing: content-box;
    height: auto;
    margin-bottom: 12px;
    overflow: hidden;
    position: relative;
    top: inherit;
    z-index: auto;
    .fff-header {
        margin: 10px;
        width: auto;
    }
    .fff-orig-product {
        max-width: 300px;
    }
    .fff-price-badge {
        right: 5px;
    }
    .fff-product {
        border: 0;
        margin: 0 0 15px 0;
        padding: 0 10px 0 0;
    }
    .fff-product-blurb {
        margin: 0px 10px;
    }
    .fff-product-title {
        color: #000;
        font-weight: normal;
        margin: 8px 0;
        text-align: left;
        word-wrap: break-word;
    }
    .fff-read-more {
        background: #c562a5;
        color: #fff;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        margin-top: 10px;
        text-align: center;
        width: 100%;
    }
    .hidden {
        display: none;
    }
    .fff-orig-product .loader {
        margin: 0px auto 0 auto;
    }
    .fff-orig-product-image.hidden {
        display: inherit;
        visibility: hidden;
    }
}

.fff-m-expandable {
    li {
        border: 0;
        list-style: none;
        margin: 0;
        width: 145px;
        img {
            display: inline;
            width: inherit;
        }
    }
    .fff-more-products-title {
        border-top: 2px solid #C562A5;
        margin: 15px 0 0 0;
        padding: 10px;
    }
}