//bg
$invertBg: #000;
$lightBg: #f5f5f5;
//colors
$mainColor: #000;
$lightColor: #E3E3E3;
$uxColor: #C0C0C0;
$mediumColor: #ce79b2;
$darkColor: #c562a5;
$invertMainColor: #fff;
$buttonColor: #004DB3;
$hoverColor : #edd0e4;
//h1, h2...
$headLineColor1:  #C768A8;
$headLineColor2:  #ffeeff;
$headLineInvertBg: #000;
$headLineLightBg: #c562a5;
//common space values
$moduleTopSpace: 15px;
$bigSpace: 20px;
$mainSpace: 15px;
$mediumSpace: 10px;
$smallSpace: 5px;
$minorSpace: 4px;
//mailonline site values
$alphaSize: 636px;
$gammaSize: 964px;
//hub specific
$nrHottest: 4;
$hottestCelebH: 402px;
$moreCelebW: 98px;
$moreCelebH: 271px;
$loadingSpace: 60px;
$loadingW: 32px;
$loadingH: 36px;
$moreBadgeSize: 58px;
$productTrendSize: 58px; //why so many different sizes for thumbs...
$productTrendContainerSize: 88px; //why so many different sizes for thumbs...
$productHottestSize: 75px;
$celebSize: 30px;
$productMoreSize: 119px;
$wrapperWidth: 129px;
//probably will be removed
$productHotBorder: 4px solid  #E7C1DA;
$productBorder: 2px solid  #dca1c9;
$trendBorder: $smallSpace solid $lightBg;
$buttonBorder: 1px solid  $uxColor;
$headlineBorder: 1px solid $mainColor;
//http://cssfontstack.com/
$fontTimes: Times, Baskerville, Georgia, serif;
$fontTimesNewRoman: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
$fontArial: Arial, "Helvetica Neue", Helvetica, sans-serif;

$moduleBorder: 1px solid #eedce8;
$moduleBorderHottest: 1px solid #453d42;

$letterSize: 20px;
$readMoreSize: 14px;
$arrowsSize: 46px;
$arrowSpace: 6px;

@mixin crossBorderBox() {
    -webkit-box-sizing: border-box; // Safari and Chrome
    -moz-box-sizing: border-box; // Firefox
    box-sizing: border-box;
    *behavior: url(/boxsizing.htc);
}

@mixin noShadow() {
    -webkit-box-shadow: none;
    -moz-box-shadow:    none;
    box-shadow:         none;
}

@mixin noSelect() {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin crossInlineBlock() {
    display: inline-block;
    *display: inline; // ie7 hack
    zoom: 1; //ie7 hack
}

@mixin crossRotation($degrees: -3) {
    -o-transform: rotate(#{$degrees}deg); // Opera
    -ms-transform: rotate(#{$degrees}deg); // IE 9
    -moz-transform: rotate(#{$degrees}deg); // Firefox
    -webkit-transform: rotate(#{$degrees}deg); // Safari and Chrome
    transform: rotate(#{$degrees}deg);
    //is breaking the ie7 look check why is not working
    //filter:  progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)});
    //-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=#{cos($degrees)}, M12=-#{sin($degrees)}, M21=#{sin($degrees)}, M22=#{cos($degrees)})";
    //zoom: 1;
}

@mixin crossRadius($size) {
    -moz-border-radius: $size;
    -webkit-border-radius: $size;
    border-radius: $size;
    behavior: url(/PIE.htc?cb=12344); //was breaking in ie10 imulating ie7-9 check if is really happening in correct browsers
    zoom: 1;
}

@mixin single-transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}

@mixin multi-transition($transitions...) {
    -webkit-transition: $transitions;
    -moz-transition: $transitions;
    -ms-transition: $transitions;
    -o-transition: $transitions;
    transition: $transitions;
}

@mixin noBackface() {
    -webkit-backface-visibility:hidden; /* Chrome and Safari */
    -moz-backface-visibility:hidden; /* Firefox */
    -ms-backface-visibility:hidden; /* Internet Explorer */
    backface-visibility:hidden;
}

@mixin preserve3d() {
    -webkit-transform-style: preserve-3d; /* Safari and Chrome */
    transform-style: preserve-3d;
}

@function crossNthChild($n: 3) {
  // If a single number for nth.
  @if type-of($n) == number {
    $nth-child: #{":first-child"};
    @for $i from 2 through $n {
      $nth-child: append($nth-child, #{"+*"});
    }
    @return $nth-child;
  }
  // If a nth-child string, need to parse the string.
  @else {
    $n: nth(children-of-ie-nth($n), 1);
    $nth-child: ":first-child";
    @for $i from 2 through $n {
      $nth-child: append($nth-child, #{"~*"});
    }
    @return $nth-child;
  }
}
