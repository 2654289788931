.femailfemailfashionhub {
  .fff-style-news-header {
    color: #c768a8;
    font-size: 32px;
    font-weight: normal;
    border-bottom: $moduleBorder;
    border-top: $moduleBorder;
    padding: 10px 0;
    font-family: $fontTimesNewRoman;
    margin-bottom: -5px;
    span {
      color: #904f82;
      margin-left: 8px;
    }
  }
  .article.article-small {
    margin-bottom: 0;
    h2 {
      font-size: 18px;
      a {
        color: #C562A5;
        font-family: $fontTimesNewRoman;
      }
      a:hover{
        color: #9d6491;
      }
    }
  }
  .article.article-large {
    position: relative;
    h2 {
      font-size: 24px;
      padding-top: 192px;
      margin-bottom: -2px;
      a {
        font-family: $fontTimesNewRoman;
        color: #C562A5;
      }
      a:hover{
        color: #9d6491;
      }
    }
    .articletext {/*To remove when CLJ ppl will fix the bug with class name which should be 'articletext-holder'*/
      padding: 0;
      p {
        display: none;
      }
    }
    .articletext-holder {
      padding: 0;
      p {
        display: none;
      }
    }
    a {
      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .fff-hub-link {
    display: none;
  }
  .fff-article-link {
    display: block;
  }
  .fff-hub-promo-banner {
    display: none;
  }
  .alpha > .femail {
    min-height: 0;
  }
}