.fff-hub {
    .fff-find-your-look {
        font-family: $fontArial;
        background: $lightBg;
        font-size: 14px;
        border-left: $buttonBorder;
        border-right: $buttonBorder;
        h6 {
            color: $mainColor;
            padding: 0;
            text-transform: none;
            margin: $mediumSpace 0 0;
        }
        > ul {
            padding: 0 $mainSpace $mainSpace;
        }
        label {
            margin: $minorSpace 0 0 0 ;
            height: 30px;
            display: block;
            > * {
                vertical-align: middle;
                margin-right: $mediumSpace;
            }
            input {
                display: none;
            }
            img {
                width: $celebSize;
                height: $celebSize;
            }
        }
        .fff-products label, .fff-trends label {
            line-height: 30px;
            padding-left: 5px;
            &:first-letter {
                text-transform: capitalize;
            }
        }
        .results li, .fff-filter-btn {
            @include single-transition(background, .2s, linear);
        }
        .results {
            li:hover, .checked {
                background: $lightColor;
            }
            .fff-no-items, .fff-no-trends, .fff-no-celebs {
                &:hover {
                    background: none;
                }
            }
        }
        .checked label {
            background: url("https://i.dailymail.co.uk/i/furniture/fff/hub/icon_close_sprite_3.png") no-repeat 98% -30px;
            &:hover {
                background-position: 98% 7px;
            }
        }
        .fff-filter-order {
            li {
                width: 50%;
                @include crossInlineBlock();
                &:first-child .fff-filter-btn {
                    border-right: none;
                }
            }
        }
        .fff-celebs-results {
            .fff-image-wrapper {
                @include crossInlineBlock();
            }
        }
        .fff-find-your-look-title {
            position: relative;
            margin-left: -1px;
            margin-top: -7px;
        }
        .fff-filter-btn {
            display: block;
            color: $mainColor;
            text-align: center;
            border: $buttonBorder;
            margin: $mediumSpace 0 0;
            padding: $smallSpace 0;
            &:hover {
                background: $lightColor;
            }
        }
        .fff-btn-checked {
            cursor: default;
            background: $lightColor;
            &:hover {
                text-decoration: none;
            }
        }
        > .fff-clear-filters {
            background: $lightColor;
            border-left: none;
            border-right: none;
            &:hover {
                background: transparent;
            }
        }
        .fff-btn-disable {
            cursor: default;
            color: #ccc;
            background: transparent;
            @include noSelect();
            &:hover {
                text-decoration: none;
            }
        }
        .fff-more-results span {
            padding-right: 15px;
            background: url("https://i.dailymail.co.uk/i/furniture/fff/hub/arrow_down.png") no-repeat 100% 50%;
        }
        .fff-less-results span {
            background: url("https://i.dailymail.co.uk/i/furniture/fff/hub/arrow_up.png") no-repeat 100% 50%;
        }
    }
    .fff-a-to-z-list {
        display: none;
        padding: 0 1px;
        margin-top: 5px;
    }
    .fff-a-to-z-list table {
        border-collapse: separate;
        border-spacing: 1px;
        border-color: gray;
        width: 100%;
        td {
            background-color: white;
            width: $letterSize;
            height: $letterSize;
            text-align: center;
            color: $mainColor;
            cursor: pointer;
            &:hover {
                color: $invertMainColor;
                background-color: $invertBg;
            }
        }
        .fff-letter-unavailable {
            background-color: $lightColor;
            cursor: default;
            color: #8D8D8D;
            &:hover {
                color: #8D8D8D;
                background-color: $lightColor;
            }
        }
        .fff-letter-selected {
            color: $invertMainColor;
            background-color: $invertBg;
        }

    }
    .fff-a-to-z-selected {
        .fff-a-to-z-search {
            background-color: rgb(218, 218, 218);
            margin-top: 7px;
        }
        .fff-celebs-results {
            padding-left: $mediumSpace;
        }
    }
    .fff-find-filter-title {
        h5 {
            margin: 0;
            padding: 0;
            color: $mainColor;
            border-bottom: none;
            @include crossInlineBlock();
        }
        a {
            color: $invertBg;
            float: right;
        }
        margin: $mediumSpace 0;
        border-bottom: $headlineBorder;
    }
}

//hack for ie, because it's doesn't update the checkbox if is hidden
.lt-ie9 .fff-hub .fff-find-your-look label input {
    display: inline;
    position: absolute;
    left: -99999px;
}

.fff-celebs-results .image-failed  {
    background: url("https://i.dailymail.co.uk/i/furniture/fff/celebs/default_celeb.png") no-repeat;
    width: $celebSize;
    height: $celebSize;
}

.fff-hub {
    .twitter-typeahead {
        width: 250px;
        position: relative;
        vertical-align: top;
        .tt-dropdown-menu {
            width: 100%;
            background: #fff;
            border: 1px solid $uxColor;
            padding-bottom: $smallSpace;
            @include crossBorderBox();
        }
        .tt-suggestion {
            line-height: 1.6em;
            padding: 0 $mediumSpace;
            &:hover {
                background-color: $hoverColor;
            }
        }
        .suggestion {
            margin: $smallSpace;
        }
        .fff-search {
            background: url("https://i.dailymail.co.uk/i/furniture/fff/hub/icon_magnify_searchbox.png") no-repeat 5px 50%;
        }
    }
    .twitter-search-btn {
        position: absolute;
        right: 0;
        top: 0px;
        z-index: 1;
        color: $mainColor;
        font-weight: bold;
        background: $lightBg;
        padding: $smallSpace;
        line-height: 1.2em;
        border: 1px solid $uxColor;
        &:hover {
            background: $lightColor;
        }
    }
    .twitter-typeahead-wrap {
        position: relative;
        width: 100%;
        input[type="text"] {
            margin: 0;
            width: 100%;
            font-size: 14px;
            background-color: #fff;
            padding: $smallSpace $smallSpace $smallSpace 30px;
            border: 1px solid $uxColor;
            @include crossBorderBox();
            &:focus {
                outline-color: transparent;
                outline-style: none;
            }
        }
        h6 {
            border-bottom: 1px $mainColor solid;
        }
    }
}
