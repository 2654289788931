#fff-popup.active .fff-m-popup {
    height: auto;
    top: 0;
}

.fff-m-popup {
    background: #fff;
    bottom: 0;
    color: #000;
    font-size: 14px;
    height: 0;
    left: 0;
    line-height: 17px;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: 100%;
    transition: top 0.5s ease, height 0.5s ease;
    -webkit-transition: top 0.5s ease, height 0.5s ease;
    width: auto;
    z-index: 1001;
    h2 {
        font-size: 30px;
        font-weight: bold;
        line-height: 32px;
        margin: 10px 10px 15px;
    }
    .fff-back {
        background: #7c496b;
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: bold;
        line-height: 46px;
        text-align: center;
    }
    .fff-buy-para {
        margin: 6px 0;
        text-align: center;
        width: auto;
        .fff-buybtn {
            background: #C562A5;
            border-radius: 2px;
            display: block;
            float: none;
            font-size: 16px;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            width: auto;
        }
    }
    .fff-close {
        background: url('https://i.dailymail.co.uk/i/furniture/reg-new/close_light.png') no-repeat 0 0;
        cursor: pointer;
        height: 28px;
        position: absolute;
        right: 6px;
        top: 6px;
        width: 28px;
        &:hover {
            background-position: 0 -28px;
        }
    }
    .fff-disclaimer {
        color: #000;
        float: none;
        font-size: 12px;
        font-weight: normal;
        margin: 15px 10px 13px;
        position: static;
        text-align: left;
    }
    .fff-mini-price-badge {
        border: 2px solid #dca1c9;
        border-radius: 29px;
        background: #fff none;
        height: 58px;
        right: 4px;
        top: 4px;
        width: 58px;
        div {
            font-weight: bold;
            line-height: 54px;
            padding: 0;
        }
    }
    .fff-more-products-title, .fff-partners {
        display: block;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 8px;
    }
    .fff-partners-links {
        border: 0;
        float: none;
        margin: 15px 10px;
        position: static;
        div {
            border-collapse: collapse;
            display: table;
            width: 100%;
        }
        li {
            border: 1px solid #e3e3e3;
            display: table-cell;
            float: none;
        }
        li a {
            border: 0;
            margin: 0 auto;
        }
        ul {
            display: table-row;
        }
        .fff-partners {
            border: 1px solid #e3e3e3;
            border-bottom-width: 0;
            line-height: 40px;
            margin-bottom: 0;
            padding: 0 8px;
        }
    }
    .fff-pink-text {
        color: #aa4f8c;
        display: block;
        font-weight: bold;
        margin-top: 8px;
    }
    .fff-popup-image {
        float: left;
        &,
        div,
        img {
            height: 275px;
            width: 100px;
        }
    }
    .fff-price-badge {
        background: #fff none;
        border: 2px solid #dca1c9;
        border-radius: 34px;
        height: 68px;
        right: 0;
        top: 0;
        width: 68px;
        div {
            font-size: 14px;
            font-weight: bold;
            line-height: 64px;
            padding: 0;
        }
    }
    .fff-product {
        border: 2px solid #e3e3e3;
        margin: 0 2px;
        padding: 6px;
        .fff-orig-product {
            padding-left: 110px;
            position: relative;
            width: auto;
        }
        .fff-product-title {
            font-size: 14px;
            height: auto;
            line-height: 17px;
            margin: 8px 0;
            min-height: 79px;
            width: auto;
        }
        .fff-buy-para {
            margin-bottom: 0;
        }
        .fff-orig-product-image {
            border: 0;
            display: block;
            margin: 0 auto;
            max-width: 140px;
            max-height: 140px;
            width: auto;
        }
    }
    .fff-product-blurb {
        margin: 15px 10px;
        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
    .fff-related {
        border-top: 2px solid #C562A5;
        margin: 20px 2px 15px;
        padding: 12px 8px 0;
        li {
            border: 1px solid #e3e3e3;
            border-left-width: 0;
            box-sizing: border-box;
            float: left;
            margin: 0 0 -1px 0;
            padding: 0 8px;
            position: relative;
            text-align: center;
            width: 50%;
        }
        li img {
            height: 130px;
            margin: 0;
            max-width: 100%;
        }
        li:nth-of-type(even) {
            border-width: 1px 0;
        }
        ul {
            margin: 13px -8px 15px;
            padding: 0;
        }
        .fff-product-title {
            font-size: 14px;
            height: 68px;
            line-height: 17px;
            overflow: hidden;
        }
    }
    .fff-related-product-item, .fff-orig-product {
        margin: 0 auto;
        position: relative;
    }
    .fff-ribbon {
        left: 0;
    }
    .fff-social-links {
        border: 0;
        margin: 13px 10px 15px;
        position: static;
        ul {
            margin-bottom: 0;
        }
    }
    .fff-title {
        display: block;
        float: none;
        margin: 10px;
    }
}